import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GSEDataComponent } from './gse-data.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

import {
	GridModule,
	ExcelModule,
	PDFModule,
} from '@progress/kendo-angular-grid';

import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { CenteredMessageModule } from '../centered-message/centered-message.module';
import { PipeModule } from '../../_helpers/pipes/pipe.module';
import { KendoGridParentModule } from '../kendo-grid-parent/kendo-grid-parent.module';
import { NavigationModule } from '../navigation/navigation.module';
import { NgxResize } from 'ngxtension/resize';

@NgModule({
	declarations: [GSEDataComponent],
	imports: [
		CommonModule,
		LoadingSpinnerModule,
		NgxResize,
		GridModule,
		ExcelExportModule,
		PDFExportModule,
		ExcelModule,
		PDFModule,
		ButtonModule,
		CenteredMessageModule,
		PipeModule,
		KendoGridParentModule,
		NavigationModule
	],
	exports: [GSEDataComponent],
})
export class GSEDataModule { }
